import React, { Component } from 'react'
import { Row, Col } from 'react-materialize';
import axios from '../../../axios';
import * as defaults from '../../../defaults';
import arrow from '../../../assets/images/arrow.png';

import classes from './GalleryWidget.module.css';

class GalleryWidget extends Component {
    state = {
        directory: null,
        images: null,
        id: 0
    }

    componentDidMount = () => {
        const splitted = this.props.link.split('/');
        const idGaleria = splitted[splitted.length - 1];

        axios.get('/galeria/' + idGaleria).then(response => {
           //console.log(response);
            if(response.data.files){
                const totalimg = response.data.files.length > 5 ? 5 : response.data.files.length;
                const array = response.data.files.slice(0, totalimg);
               //console.log(response.data);
                this.setState({
                    directory: response.data.directory,
                    id: idGaleria,
                    images: array,
                });
            }
            
        }).catch(error => {
           //console.log(error);
        });

    }

    onClickImage = () => {
        this.props.history.push({pathname: '/galerias/' + this.state.id});
    }

    render() {
        let listImages = null;
        if (this.state.images) {
            listImages = this.state.images.map(img => (
                <Col m={2} key={img}>
                    <div onClick={() => this.onClickImage()}
                        className={classes.Image}
                        style={{ backgroundImage: "url(" + defaults.GALERIA_DETALHE_IMG_PATH + this.state.directory + "/" + img + ")", cursor: 'pointer' }}></div>
                </Col>
            ))
        }

        return (
            <div className={classes.GalleryWidget}>
                <Row style={{ marginBottom: 0 }}>
                    {listImages}
                    <Col m={2}>
                        <div className={classes.Center}>
                            <img alt="" src={arrow} onClick={() => this.onClickImage()} style={{ cursor: 'pointer' }} />
                        </div>
                    </Col>
                </Row>

            </div>
        )
    }
}

export default GalleryWidget;