import React from 'react';
import { Row, Col, Icon } from 'react-materialize';

import classes from './ItemList.module.css';

const itemList = (props) => {
    return (
        <Row className={classes.ItemList}>
            <Col m={10} s={9}>
                <p>{props.title}</p>
            </Col>
            <Col style={{textAlign: 'right'}} m={2} s={3}>
                <div style={{display: 'inline-block'}} onClick={() => props.edithandler(props.id)}><Icon>create</Icon></div>
                <div style={{display: 'inline-block'}} onClick={() => props.deletehandler(props.id)}><Icon>clear</Icon></div>
            </Col>
        </Row>
    )
}

export default itemList;