import React, { Component } from 'react';
import AdSense from 'react-adsense';
import Auxiliar from '../../hoc/Auxiliar/Auxiliar';
import Toolbar from '../../components/Nav/Toolbar/Toolbar';
import SideDrawer from '../../components/Nav/SideDrawer/SideDrawer';
import { Switch, Route } from 'react-router';
import classes from './Layout.module.css';
import Index from '../../containers/Index/Index';
import Gallery from '../../containers/Gallery/Gallery';
import Notices from '../../containers/Notices/Notices';
import Columnist from '../../containers/Columnist/Columnist';
import Contact from '../../containers/Contact/Contact';
import Radios from '../../containers/Radios/Radios';
import GalleryDetail from '../../containers/Gallery/GalleryDetail/GalleryDetail';
import NoticesDetail from '../../containers/Notices/NoticesDetail/NoticesDetail';
import Column from '../../containers/Columnist/Column/Column';
//import WithTracker from '../../hoc/WithTracker/WithTracker';

class Layout extends Component {
    state = {
        showSideDrawer: false
    }

    sideDrawerClosedHandler = () => {
        this.setState({ showSideDrawer: false });
    }

    sideDrawerToggleHandler = () => {
        this.setState((prevState) => {
            return { showSideDrawer: !prevState.showSideDrawer }
        });
    }

    render() {
        return (
            <Auxiliar>
                <Toolbar drawerToggleClicked={this.sideDrawerToggleHandler} />
                <SideDrawer open={this.state.showSideDrawer} closed={this.sideDrawerClosedHandler} />
                <AdSense.Google
                    client='ca-pub-3674540814826814'
                    slot='3448861622'
                    style={{ textAlign: 'center', display: 'block', marginTop: '50px', marginBottom: '-30px' }}
                    format='auto'
                    responsive='true'/>


                <main className={classes.container}>
                    <Switch>
                        <Route path="/" exact component={Index} />
                        <Route path="/noticias" exact component={Notices} />
                        <Route path="/galerias" exact component={Gallery} />
                        <Route path="/radios" exact component={Radios} />
                        <Route path="/colunistas" exact component={Columnist} />
                        <Route path="/contato" exact component={Contact} />
                        <Route path="/galerias/:id" exact component={GalleryDetail} />
                        <Route path="/noticias/:id" exact component={NoticesDetail} />
                        <Route path="/colunas/:id" exact component={Column} />
                        {/*<Route path="/" exact component={WithTracker(Index)} />
                        <Route path="/noticias" exact component={WithTracker(Notices)} />
                        <Route path="/galerias" exact component={WithTracker(Gallery)} />
                        <Route path="/radios" exact component={WithTracker(Radios)} />
                        <Route path="/colunistas" exact component={WithTracker(Columnist)} />
                        <Route path="/contato" exact component={WithTracker(Contact)} />
                        <Route path="/galerias/:id" exact component={WithTracker(GalleryDetail)} />
                        <Route path="/noticias/:id" exact component={WithTracker(NoticesDetail)} />
        <Route path="/colunas/:id" exact component={WithTracker(Column)} />*/}
                    </Switch>
                </main>
            </Auxiliar>
        )
    }
}

export default Layout;