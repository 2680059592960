import React from 'react';
import { Col, Row } from 'react-materialize';
import Notice from './Notice/Notice';

import classes from './Header.module.css';

const notices = (props) => {

    const notice_mapped = props.notices.map(not => (
        <Notice 
            key={not.id}
            id={not.id}
            type={not.type}
            image={not.image}
            title={not.title}
            body={not.body}
            clickhandler={props.clickhandler}/>
    ));

    return (
        <Row style={{marginBottom: 0}}>
            <Col s={12} m={6} l={6}>
                {notice_mapped[0]}
            </Col>
            <Col s={12} m={6} l={6}>
                <Row>
                    <Col style={{ padding: 0 }} s={12} m={12} l={12}>
                        {notice_mapped[1]}
                    </Col>
                </Row>
                <Row>
                    <Col className={classes.Padding} s={6} m={12} l={6}>
                        {notice_mapped[2]}
                    </Col>
                    <Col className={classes.HideOnMedium} style={{ paddingRight: 0 }} s={6} m={6} l={6}>
                        {notice_mapped[3]}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default notices;