import React from 'react';
import NavigationItens from '../NavItens/NavItens';
import classes from './SideDrawer.module.css';
import Backdrop from '../../UI/Backdrop/Backdrop';
import Auxiliar from '../../../hoc/Auxiliar/Auxiliar';

const sideDrawer = (props) => {

    let attachedClasses = [classes.SideDrawer, classes.Close];

    if (props.open){
        attachedClasses = [classes.SideDrawer, classes.Open];
    }

    return (
        <Auxiliar>
            <Backdrop show={props.open} click={props.closed} />
            <div className={attachedClasses.join(' ')}>
                {/*<div className={classes.Logo}>
                    <Logo img={rsLogo}/>
                </div>
                <div className={classes.hr}></div>*/}
                <nav>
                    <NavigationItens />
                </nav>
            </div>
        </Auxiliar>
    );
};

export default sideDrawer;