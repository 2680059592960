import React, { Component } from 'react';
import TreeView from 'deni-react-treeview';
import axios from '../../../../axios';
import Cookies from 'universal-cookie';
import { Row, Col } from 'react-materialize';
import Input from '../../../../components/UI/Input/Input';
import Button from '../../../../components/UI/Button/Button';
import classes from './Edit.module.css';

class Edit extends Component {
    state = {
        id: 0,
        form: null,
        formIsValid: false,
        perm: null
    }
 
    componentDidMount() {
        const cookies = new Cookies();

        if (this.props.match.params.id > 0) {

            axios.get('/painel/usuario/' + this.props.match.params.id, { headers: { 'J-Token': cookies.get("token") } })
            .then(response => {
               //console.log(response.data);

                this.setState({ 
                    ...this.state, 
                    id: this.props.match.params.id,
                    formIsValid: true,
                    form: {
                        usuario: {
                            elementtype: 'input',
                            elementconfig: {
                                type: 'text',
                                label: 'Usuário'
                            },
                            value: response.data.user,
                            validation: {
                                required: true,
                            },
                            valid: true,
                            touched: true
                        },
                        email: {
                            elementtype: 'input',
                            elementconfig: {
                                type: 'email',
                                label: 'E-mail'
                            },
                            value: response.data.email,
                            validation: {
                                required: true,
                            },
                            valid: true,
                            touched: true,
                            
                        },
                    },
                });

            }).catch(error => {
               //console.log(error);
            });
           
        } else {

            this.setState({
                ...this.state.form,
                form: {
                    usuario: {
                        elementtype: 'input',
                        elementconfig: {
                            type: 'text',
                            label: 'Usuário'
                        },
                        value: '',
                        validation: {
                            required: true,
                        },
                        valid: false,
                        touched: false
                    },
                    senha: {
                        elementtype: 'input',
                        elementconfig: {
                            type: 'password',
                            label: 'Senha'
                        },
                        value: '',
                        validation: {
                            required: true,
                        },
                        valid: false,
                        touched: false,
                        
                    },
                    email: {
                        elementtype: 'input',
                        elementconfig: {
                            type: 'email',
                            label: 'E-mail'
                        },
                        value: '',
                        validation: {
                            required: true,
                        },
                        valid: false,
                        touched: false,
                        
                    },
                },
            });
        }

        axios.get('/painel/permissoes_edit/' + this.props.match.params.id, { headers: { 'J-Token': cookies.get("token") } }).then(response => {

            let perm = [];
            let child = [];

            response.data.map(p => {
                if (p.pai_id === null) {
                    perm.push({
                        id: p.id,
                        text: p.nome,
                        state: parseInt(p.granted, 10),
                        children: []
                    });
                } else {
                    child.push({
                        id: p.id,
                        pai_id: p.pai_id,
                        text: p.nome,
                        state: parseInt(p.granted, 10),
                        isLeaf: true
                    });
                }

                return "";
            });

            if (perm.length > 0 && child.length > 0) {
                for (var i = 0, iLen = perm.length; i < iLen; i++) {
                    for (var j = 0, jLen = child.length; j < jLen; j++) {
                        if (child[j].pai_id === perm[i].id) {
                            perm[i].children.push(child[j]);
                        }
                    }
                }
               this.setState({...this.state, perm: perm});
            }
        }).catch(error => {
           //console.log(error);
        });
    }

    checkSelectedItems = () => {
        let api = this.refs.treeview.api;
        let selected = [];

        let items = api.getItems();

        for (var i = 0, iLen = items.length; i < iLen; i++) {
            if (items[i].state && (items[i].state === 1 || items[i].state === 3)) {
                selected.push(items[i].id);

                if (items[i].children) {
                    var c = items[i].children;

                    for (var j = 0, jLen = c.length; j < jLen; j++) {
                        if (c[j].state && (c[j].state === 1 || c[j].state === 3)) {
                            selected.push(c[j].id)
                        }
                    }
                }
            }

        }

        return selected;
    }

    submitFormHandler = () => {
       //console.log(this.checkSelectedItems());

        const formData = new FormData();

        formData.append("id", this.state.id);
        formData.append("usuario", this.state.form['usuario'].value);
        formData.append("email", this.state.form['email'].value);
        formData.append("perm", this.checkSelectedItems());

        if(this.state.id === 0)
            formData.append("senha", this.state.form['senha'].value);

        const cookies = new Cookies();

        axios.post("/painel/usuario", formData, { headers: {'J-Token': cookies.get("token")} }).then(resp => {
           //console.log(resp);
            this.props.history.push({ pathname: '/painel/usuarios' });
        }).catch(err => {
            if (err.response.status === 401) {
                this.props.history.push({ pathname: '/painel/login' });
            }
        });
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedForm = {
            ...this.state.form
        }

        const updatedFormElement = { ...updatedForm[inputIdentifier] }

        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedForm[inputIdentifier] = updatedFormElement;
        let formIsValid = true;
        for (let inputIdentifiers in updatedForm) {
            formIsValid = updatedForm[inputIdentifiers].valid && formIsValid;
        }
        this.setState({ ...this.state, form: updatedForm, formIsValid: formIsValid });
       //console.log(updatedForm);
    }

    checkValidity(value, rules) {
        let isValid = true;

        if (!rules) {
            return isValid;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid;
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid;
        }

        return isValid;
    }

    render() {

        const formElementsArray = [];

        if (this.state.form){
            for (let key in this.state.form) {
                formElementsArray.push({
                    id: key,
                    config: this.state.form[key]
                });
            }
        }

        let form = (
            <form>
                {formElementsArray.map(formElement => (
                    <Input key={formElement.id}
                        elementtype={formElement.config.elementtype}
                        elementconfig={formElement.config.elementconfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        mask={formElement.config.mask}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)} />
                ))}
            </form>
        );

        let tree = null;

        if (this.state.perm){
            tree = (<TreeView
                ref="treeview"
                items={this.state.perm}
                showCheckbox
                showIcon={false} />);
        }

        return (
            <div className={classes.Edit}>
                <Row>
                    <Col s={12} m={6}>
                    <h1>{this.state.id > 0 ? "Editar" : "Adicionar"} usuário</h1>
                        {form}
                        <Button disabled={!this.state.formIsValid} onclick={this.submitFormHandler}>Enviar</Button>
                    </Col>
                    <Col s={12} m={6}>
                        <h1>Permissões</h1>
                        {tree}

                    </Col>
                </Row>
                
            </div>

        )
    }
}

export default Edit;