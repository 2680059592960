import React from 'react';
import { Button } from 'react-materialize';
import classes from './Button.module.css';

const button = (props) => {
    return (<div className={classes.Button}>
        <Button disabled={props.disabled} waves='light' onClick={props.onclick}>{props.children}</Button>
    </div>
    )
}

export default button;