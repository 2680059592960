import React from 'react';
import classes from './Notice.module.css';
import * as defaults from '../../../../../defaults';

const notice = (props) => {

    return (
        <div onClick={() => props.clickhandler(props.id)} className={classes[props.type]} style={{ backgroundImage: "url(" + defaults.NOTICE_IMG_PATH + props.image + ")" }}>
            <div className={classes.Content}>
                <h1>{props.title}</h1>
                <p>{props.body}</p>
            </div>
        </div>
    );
};

export default notice;