import React from 'react';
import Notice from './Notice/Notice';
import Auxiliar from '../../../../hoc/Auxiliar/Auxiliar';
import classes from './List.module.css';

const list = (props) => {
    return (
        <div className={classes.List}>
            {
                props.notices.map(not => (
                    <Auxiliar key={not.id}>
                        <Notice
                            id={not.id}
                            image={not.image}
                            title={not.title}
                            body={not.body}
                            clickhandler={props.clickhandler} />
                        <hr />
                    </Auxiliar>
                ))
            }
            <h2 style={{cursor: 'pointer'}} onClick={() => props.history.push({pathname: '/noticias'})}>VER TODAS</h2>
        </div>
    );
}

export default list;