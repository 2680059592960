import React from 'react';
import Notice from './Notice/Notice';

import classes from './Notices.module.css';

const notices = (props) => {
    return (
        <div className={classes.Notices}>
            {
                props.notices.map(not => (
                    <Notice key={not.id} id={not.id} title={not.title} clickhandler={props.clickhandler} />
                ))
            }
        </div>
    )
}

export default notices;