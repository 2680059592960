import React from 'react';
import { Preloader } from 'react-materialize';
import classes from './Spinner.module.css';

const spinner = (props) => {
    return (
        <div className={classes.SpinContainer}>
            <Preloader className={classes.Spinner} flashing />
        </div>
    )
}

export default spinner;