import React from 'react';
import { Input } from 'react-materialize';
import InputMask from 'react-input-mask';
import classes from './Input.module.css';

const input = (props) => {
    let inputElement = null;
    const inputClasses = [classes.Input];

    if (props.invalid && props.shouldValidate && props.touched) {
        inputClasses.push(classes.Invalid);
    }

    switch (props.elementtype) {
        case ('input'):
            if (props.mask) {
                inputElement = (<InputMask mask={props.mask} defaultValue={props.value} onChange={props.changed}>
                    {(inputProps) => <Input s={12} {...inputProps} {...props.elementconfig} /> }
                </InputMask>);
            } else {
                inputElement = <Input s={12} {...props.elementconfig} defaultValue={props.value} onChange={props.changed} />;
            }
            break;
        case ('select'):
            inputElement = (
                <Input s={12} type='select' label={props.elementconfig.label} defaultValue={props.value} onChange={props.changed}>
                    {props.elementconfig.options.map(option => (
                        <option key={option.value} value={option.value}>{option.displayValue}</option>
                    ))}
                </Input>
            );
            break;
        case ('textarea'):
            inputElement = <Input type='textarea' style={{heigth: '300px'}} s={12} {...props.elementconfig} defaultValue={props.value} onChange={props.changed} />;
            break;
        default:
            inputElement = <Input s={12} {...props.elementconfig} value={props.value} onChange={props.changed} />;
    }

    return (
        <div className={inputClasses.join(' ')}>
            {inputElement}
        </div>
    )
}

export default input;