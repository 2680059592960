import React from 'react';
import classes from './Toolbar.module.css';
import Logo from '../../UI/Logo/Logo';
import NavItens from '../NavItens/NavItens';
import NavItensAdm from '../NavItens/NavItensAdm';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';
import rsLogo from '../../../assets/images/logo.png';
import admLogo from '../../../assets/images/logo.adm.png';

const toolbar = (props) => (
    <header className={classes.Toolbar}>
        <DrawerToggle clicked={props.drawerToggleClicked}/>
        <div className={[classes.Logo, classes.container].join(' ')}>
            <Logo img={props.admin ? admLogo : rsLogo} admin={props.admin}/>
        </div>
        
        <nav className={[classes.DesktopOnly, classes.container].join(' ')}>
           {props.admin ? <NavItensAdm routes={props.routes}/> : <NavItens/>}
        </nav>
        
    </header>
);

export default toolbar;