import React, { Component } from "react";
import { Col, Row } from "react-materialize";

import classes from './Index.module.css';
import HeaderNotices from '../../components/Index/Notices/Header/Header';
import MiddleNotices from '../../components/Index/Notices/Middle/Middle';
import List from '../../components/Index/Notices/List/List';
import Video from '../../components/Index/Video/Video';
import Ad from '../../components/Index/Ad/Ad';
import Gallery from '../../components/Index/Gallery/Gallery';
import Facebook from '../../components/Index/Facebook/Facebook';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import ReactGA from 'react-ga';
import axios from '../../axios';
import AdSense from 'react-adsense';


class Index extends Component {
  state = {
    header_notices: null,
    list_notices: null,
    middle_notices: null,
    gallery: null,
    midAd: null,
    videoAd: null,
    bottomAd: null,
    bigAd: null,
    videoId: null,
  };

  componentDidMount() {
    //ReactGA.initialize('UA-69147949-1');

    axios.get('https://www.googleapis.com/youtube/v3/search?channelId=UCCwscQuEbOf9BXK_93XisQg&order=date&part=snippet&type=video&maxResults=1&key=AIzaSyDBlNYFdemCcjgdch1-ZIVgKX4b78IHung')
    .then(response => {
        this.setState({
        ...this.state,
        videoId: response.data.items[0].id.videoId
    })
    }).catch(error => {
        //console.log(error);
    });

    axios
      .get("/noticias/6/0")
      .then((response) => {
        this.setState({
          ...this.state,
          header_notices: response.data.slice(0, 4),
          list_notices: response.data.slice(4, 6),
        });
      })
      .catch((error) => {
        //console.log(error);
      });

    axios
      .get("/comentarios/3/0")
      .then((response) => {
        this.setState({
          ...this.state,
          middle_notices: response.data,
        });
      })
      .catch((error) => {
        //console.log(error);
      });

    axios
      .get("/galerias/3/0")
      .then((response) => {
        this.setState({
          ...this.state,
          gallery: response.data,
        });
      })
      .catch((error) => {
        //console.log(error);
      });

    this.getAds();

    this.interval = setInterval(() => {
      this.getAds();
    }, 20000);
  }

  getAds = () => {
    axios
      .get("/props/mid/2")
      .then((response) => {
        //console.log(response);
        this.setState({
          ...this.state,
          midAd: response.data,
        });
      })
      .catch((error) => {
        //console.log(error);
      });

    axios
      .get("/props/video/2")
      .then((response) => {
        this.setState({
          ...this.state,
          videoAd: response.data,
        });
      })
      .catch((error) => {
        //console.log(error);
      });

    axios
      .get("/props/bot/1")
      .then((response) => {
        this.setState({
          ...this.state,
          bottomAd: response.data,
        });
      })
      .catch((error) => {
        //console.log(error);
      });

    axios
      .get("/props/big/1")
      .then((response) => {
        this.setState({
          ...this.state,
          bigAd: response.data,
        });
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  onClickBanner = (id) => {
    /*ReactGA.event({
            category: 'Banner',
            action: 'Click em um ad',
            label: 'id: ' + id
          });*/
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  columnistClickHandler = (id) => {
    this.props.history.push({ pathname: "/colunistas" });
  };

  columnClickHandler = (id) => {
    this.props.history.push({ pathname: "/colunas/" + id });
  };

  noticeClickHandler = (id) => {
    this.props.history.push({ pathname: "/noticias/" + id });
  };

  galleryClickHandler = (id) => {
    this.props.history.push({ pathname: "/galerias/" + id });
  };

  render() {
    let header = null;
    if (this.state.header_notices) {
      header = (
        <HeaderNotices
          notices={this.state.header_notices}
          clickhandler={this.noticeClickHandler}
        />
      );
    }

    let list = null;
    if (this.state.list_notices) {
      list = (
        <List
          notices={this.state.list_notices}
          clickhandler={this.noticeClickHandler}
          history={this.props.history}
        />
      );
    }

    let middle = null;
    if (this.state.middle_notices) {
      middle = (
        <MiddleNotices
          notices={this.state.middle_notices}
          columnClick={this.columnClickHandler}
          columnistClick={this.columnistClickHandler}
        />
      );
    }

    let gal = null;
    if (this.state.gallery && this.state.bigAd) {
      gal = (
        <Gallery
          imgs={this.state.gallery}
          galleryclickhandler={this.galleryClickHandler}
          ad={this.state.bigAd[0]}
        />
      );
    }

    let midAds = null;
    if (this.state.midAd) {
      midAds = this.state.midAd.map((ad) => (
        <Col key={ad.id} s={6}>
          <Ad
            onClick={() => this.onClickBanner(ad.id)}
            link={ad.link}
            image={ad.image}
            mobile={ad.mobile}
            height="80px"
          />
        </Col>
      ));
    }

        let bottomAds = null;
        if (this.state.bottomAd){
            // bottomAds = this.state.bottomAd.map(ad => (
            //     <Ad onClick={() => this.onClickBanner(ad.id)} key={ad.id} link={ad.link} image={ad.image} mobile={ad.mobile} height="80px" />
            // ));

            bottomAds = <AdSense.Google
            client='ca-pub-3674540814826814'
            slot='4350879756'
            style={{ display: 'block', height: '80px' }}
            format='fluid'
            responsive='true'/>
        }

    let videoAdLeft = null;
    let videoAdRight = null;

    if (this.state.videoAd && this.state.videoAd.length > 0) {
      videoAdLeft = (
        <Ad
          onClick={() => this.onClickBanner(this.state.videoAd[0].id)}
          link={this.state.videoAd[0].link}
          image={this.state.videoAd[0].image}
          mobile={this.state.videoAd[0].mobile}
          height="60px"
        />
      );
      videoAdRight = (
        <Ad
          onClick={() => this.onClickBanner(this.state.videoAd[1].id)}
          link={this.state.videoAd[1].link}
          image={this.state.videoAd[1].image}
          mobile={this.state.videoAd[1].mobile}
          height="60px"
        />
      );
    }

    return (
      <div className={classes.Index}>
        {header}
        <Row>{midAds}</Row>
        {/* {middle} */}
        <Row>
          <Col m={6} s={12}>
            {list}
          </Col>
          <Col m={6} s={12}>
            <Video videoId={this.state.videoId} />
            <Row>
              <Col s={6} style={{ paddingLeft: 0 }}>
                {videoAdLeft}
              </Col>
              <Col s={6} style={{ paddingRight: 0 }}>
                {videoAdRight}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col s={12} m={6}>
            {gal}
            {bottomAds}
          </Col>
          <Col s={12} m={6}>
            <Facebook />
          </Col>
        </Row>
      </div>
    );
  }
}

export default withErrorHandler(Index, axios);
