import React from 'react';
import { Row, Col } from 'react-materialize';
import Media from "react-media";

import classes from './DetailWithAds.module.css';

const detailWithAds = (props) => {
    return (
        <div className={classes.DetailWithAds}>
            <Row>
                <Media query="(max-width: 599px)">
                    {matches =>
                        matches ? props.ads.map(ad => (
                            <Col key={ad.id} s={6} onClick={() => props.onClick(ad.id)}>
                                <a href={ad.link} target="_blank" rel="noopener noreferrer">
                                    <img src={ad.imagem_mobile} alt={ad.nome} />
                                </a>
                            </Col>
                        )
                        ) : props.ads.map(ad => (
                            <Col key={ad.id} s={12} onClick={() => props.onClick(ad.id)}>
                                <a href={ad.link} target="_blank" rel="noopener noreferrer">
                                    <img src={ad.imagem} alt={ad.nome} />
                                </a>
                            </Col>
                        )
                        )
                    }
                </Media>
            </Row>
        </div>

    )
}

export default detailWithAds;