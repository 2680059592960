import React, { Component } from 'react';
import Gallery from 'react-grid-gallery';
import Spinner from '../../../components/UI/Spinner/Spinner';
import Pagination from '../../../components/UI/Pagination/Pagination';
import axios from '../../../axios';
import * as defaults from '../../../defaults';
import { Row } from 'react-materialize';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';


class GalleryDetail extends Component {
    state = {
        imagesTotal: null,
        imagesShow: null,
        firstRun: true,
        items: 160,
        directory: null
    }

    componentDidMount(){
        if ( this.props.match.params.id ) {
            axios.get('/galeria/' + this.props.match.params.id).then(response => {
                this.setState({
                    ...this.state,
                    firstRun: false,
                    imagesTotal: response.data.files,
                    items: response.data.files.length,
                    directory: response.data.directory
                });
                this.loadPage(0);
            }).catch(error => {
               //console.log(error);
            });
        }
        
    }

    onSelectHandler = (page) => {
        if (page === 1 && this.state.firstRun) return;
        this.loadPage((page - 1) * 20);
    }

    loadPage = (offset) => {
        const updatedImages = this.state.imagesTotal.slice(offset, offset+20).map(img => {
            return {
                src: defaults.GALERIA_DETALHE_IMG_PATH + this.state.directory + "/" + img,
                thumbnail: defaults.GALERIA_DETALHE_IMG_PATH + this.state.directory + "/" + img,
                thumbnailWidth: 320,
                thumbnailHeight: 212,
            }
        });
        this.setState({
            ...this.state,
            imagesShow: updatedImages
        })
        window.scrollTo(0, 0)
    }

    render() {
        let imgs = <Spinner/>
        if (this.state.imagesShow){
            imgs = (
                <div>
                    <Row style={{marginTop: '30px'}}>
                        <Gallery enableImageSelection={false} images={this.state.imagesShow}/>
                    </Row>
                    <Pagination items={this.state.items} initialPage={1} onChangePage={this.onSelectHandler} pageSize={20} />
                </div>
            );
        }

        return (
            <div>
                {imgs}
            </div>
        )
    }
}

export default withErrorHandler(GalleryDetail,axios);