import React, { Component } from "react";
import "./NewFileUploader.css";
import Image from "../../../assets/images/upload.svg";

class Dropzone extends Component {
        constructor(props) {
                super(props);
                this.state = { hightlight: false };
                this.fileInputRef = React.createRef();

                this.openFileDialog = this.openFileDialog.bind(this);
                this.onFilesAdded = this.onFilesAdded.bind(this);
                this.onDragOver = this.onDragOver.bind(this);
                this.onDragLeave = this.onDragLeave.bind(this);
                this.onDrop = this.onDrop.bind(this);
        }

        openFileDialog() {
                if (this.props.disabled) return;
                this.fileInputRef.current.click();
        }

        onFilesAdded(evt) {
               //console.log("fileadded");
                if (this.props.disabled) return;
                const files = evt.target.files;
                
                if (this.props.onFilesAdded) {
                        const array = this.fileListToArray(files);
                        this.props.onFilesAdded(array);
                }
        }

        onDragOver(evt) {
                evt.preventDefault();

                if (this.props.disabled) return;

                this.setState({ hightlight: true });
        }

        onDragLeave() {
                this.setState({ hightlight: false });
        }

        onDrop(event) {
                event.preventDefault();

                if (this.props.disabled) return;

                const files = event.dataTransfer.files;
               //console.log(files);
                if (this.props.onFilesAdded) {
                        const array = this.fileListToArray(files);
                        this.props.onFilesAdded(array);
                }
                this.setState({ hightlight: false });
        }

        fileListToArray(list) {
                const array = [];
                for (var i = 0; i < list.length; i++) {
                        array.push(list.item(i));
                }
                return array;
        }

        render() {
                return (
                        <div
                                className='Dropzone'
                                onClick={this.openFileDialog}
                                style={{ cursor: this.props.disabled ? "default" : "pointer" }}
                        >
                                <input
                                        ref={this.fileInputRef}
                                        className="FileInput"
                                        type="file"
                                        multiple={this.props.multiple}
                                        accept="image/*"
                                        onChange={this.props.onChange}
                                />
                                <img alt="upload" className="Icon" src={Image} />
                        </div>
                );
        }
}

export default Dropzone;
