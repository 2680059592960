import React from 'react';
import classes from './Image.module.css';
import * as defaults from '../../../../defaults';

const image = (props) => {
    return (
        <div onClick={() => props.clickhandler(props.image.id)} 
            className={classes.Image} 
            style={{ backgroundImage: "url("+ defaults.GALERIA_IMG_PATH + props.image.image + ")", cursor: 'pointer' }}></div>
    );
}

export default image;