import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import Layout from './hoc/Layout/Layout';
import AdminLayout from './hoc/AdminLayout/AdminLayout';
import WithoutToolbarLayout from './hoc/WithoutToolbarLayout/WithoutToolbarLayout';

class App extends Component {
  render() {
    return (
      <Switch>
        <Route path="/" exact component={Layout} />
        <Route path="/noticias" exact component={Layout} />
        <Route path="/galerias" exact component={Layout} />
        <Route path="/radios" exact component={Layout} />
        <Route path="/colunistas" exact component={Layout} />
        <Route path="/contato" exact component={Layout} />
        <Route path="/galerias/:id" exact component={Layout} />
        <Route path="/noticias/:id" exact component={Layout} />
        <Route path="/colunas/:id" exact component={Layout} />

        <Route path="/painel/login" exact component={WithoutToolbarLayout} />

        <Route path="/painel" component={AdminLayout} />
        
      </Switch>
    );
  }
}

export default App;
