import React, { Component } from 'react';
import { Row, Col } from 'react-materialize';
import Input from '../../../../components/UI/Input/Input';
import Button from '../../../../components/UI/Button/Button';
import axios from '../../../../axios';
import Cookies from 'universal-cookie';
import NewFileUploader from '../../../../components/UI/NewFileUploader/NewFileUploader';
import TinyEditor from '../../../../components/Panel/TinyEditor/TinyEditor';
import * as defaults from '../../../../defaults';
import classes from './Edit.module.css';

class Edit extends Component {
    state = {
        thumb: null,
        thumbPreview: null,
        header: null,
        headerPreview: null,
        id: 0,
        form: null,
        formIsValid: false,
        text: ''
    }

    componentDidMount() {
        const cookies = new Cookies();

        axios.get('/painel/usuarios_config', { headers: {'J-Token': cookies.get("token")} }).then(response => {
            
            if (this.props.match.params.id > 0) {

                axios.get('/painel/coluna/' + this.props.match.params.id, { headers: { 'J-Token': cookies.get("token") } }).then(response2 => {
                    //console.log(response2.data);
    
                    this.setState({
                        ...this.state,
                        text: response2.data.text,
                        thumbPreview: defaults.COLUNISTA_IMG_PATH + response2.data.profile,
                        headerPreview: defaults.COLUNISTA_HEADER_PATH + response2.data.header,
                        id: this.props.match.params.id,
                        formIsValid: true,
                        form: {
                            nome: {
                                elementtype: 'input',
                                elementconfig: {
                                    type: 'text',
                                    label: 'Nome da coluna'
                                },
                                value: response2.data.coluna,
                                validation: {
                                    required: true,
                                },
                                valid: true,
                                touched: true
                            },
                            usuario: {
                                elementtype: 'select',
                                elementconfig: {
                                    options: response.data,
                                    label: 'Usuário'
                                },
                                value: response2.data.id_user,
                                valid: true
                            }
                        },
                    });

                }).catch(error => {
                   //console.log(error);
                });
            } else {
                this.setState({
                    ...this.state.form,
                    form: {
                        nome: {
                            elementtype: 'input',
                            elementconfig: {
                                type: 'text',
                                label: 'Nome da coluna'
                            },
                            value: '',
                            validation: {
                                required: true,
                            },
                            valid: false,
                            touched: false
                        },
                        usuario: {
                            elementtype: 'select',
                            elementconfig: {
                                options: response.data,
                                label: 'Usuário'
                            },
                            value: response.data[0].value,
                            valid: true
                        }
                    },
                });
            }

        }).catch(error => {
           //console.log(error);
        });
        
    }

    onChangeThumb = (evt) => {
        const files = evt.target.files;
        this.setState({ thumbPreview: URL.createObjectURL(files[0]), thumb: files[0] });
        //console.log(files);
    }

    onChangeHeader = (evt) => {
        const files = evt.target.files;
        this.setState({ headerPreview: URL.createObjectURL(files[0]), header: files[0] });
        //console.log(files);
    }

    onChangeEditor = (value) => {
        //const data = editor.getData();
        this.setState({ text: value });
        //console.log( data );
    }

    submitFormHandler = () => {
        this.sendBasicData();
    }

    sendBasicData = () => {
        const formData = new FormData();

        formData.append("id", this.state.id);
        formData.append("column", this.state.form['nome'].value);
        formData.append("user", this.state.form['usuario'].value);
        formData.append("text", this.state.text);
        formData.append("thumb", this.state.thumb);
        formData.append("header", this.state.header);

       //console.log(formData);

        const cookies = new Cookies();

        axios.post("/painel/coluna", formData, { headers: {'J-Token': cookies.get("token")} }).then(resp => {
            //console.log(resp);
            this.props.history.push({ pathname: '/painel/colunas' });
            //this.sendGallery(resp.data.directory);
        }).catch(err => {
            if (err.response.status === 401) {
                this.props.history.push({ pathname: '/painel/login' });
            }
        });
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedForm = {
            ...this.state.form
        }

        const updatedFormElement = { ...updatedForm[inputIdentifier] }

        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedForm[inputIdentifier] = updatedFormElement;
        let formIsValid = true;
        for (let inputIdentifiers in updatedForm) {
            formIsValid = updatedForm[inputIdentifiers].valid && formIsValid;
        }
        this.setState({ form: updatedForm, formIsValid: formIsValid });
       //console.log(updatedForm);
    }

    checkValidity(value, rules) {
        let isValid = true;

        if (!rules) {
            return isValid;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid;
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid;
        }

        return isValid;
    }

    render() {
        const formElementsArray = [];

        for (let key in this.state.form) {
            formElementsArray.push({
                id: key,
                config: this.state.form[key]
            });
        }

        let form = (
            <form>
                {formElementsArray.map(formElement => (
                    <Input key={formElement.id}
                        elementtype={formElement.config.elementtype}
                        elementconfig={formElement.config.elementconfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        mask={formElement.config.mask}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)} />
                ))}
            </form>
        );

        var thumbImage = {
            backgroundImage: 'url(' + this.state.thumbPreview + ')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: '180px',
            height: '180px'
        }

        var headerImage = {
            backgroundImage: 'url(' + this.state.headerPreview + ')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: '100%',
            height: '250px'
        }

        return (
            <div className={classes.Edit}>
                <Row>
                    <h1>Header</h1>
                    <div style={headerImage}>
                        <NewFileUploader onChange={this.onChangeHeader} />
                    </div>
                </Row>
                <Row>
                    <Col m={9} s={12}>
                        <h1>Dados coluna</h1>
                        {form}
                    </Col>
                    <Col m={3} s={12}>
                        <h1>Thumb</h1>
                        <div style={thumbImage}>
                            <NewFileUploader multiple={false} onChange={this.onChangeThumb} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <TinyEditor data={this.state.text} onChange={this.onChangeEditor} />
                    <Button disabled={!this.state.formIsValid || (!this.state.thumb && this.state.id === 0) || (!this.state.header && this.state.id === 0) || !this.state.text} onclick={this.submitFormHandler}>Enviar</Button>
                </Row>
            </div>
        )
    }
}

export default Edit;