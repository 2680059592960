import React, { Component } from 'react';
import ListNotices from '../../components/Notices/Notices';
import Spinner from '../../components/UI/Spinner/Spinner';
import Pagination from '../../components/UI/Pagination/Pagination';
import axios from '../../axios';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';

import classes from './Notices.module.css';

class Notices extends Component {
    state = {
        notices: null,
        firstRun: true,
        items: 100
    }

    componentDidMount() {
        axios.get('/totalnoticia').then(response => {
            this.setState({
                ...this.state,
                items: parseInt(response.data.count, 10)
            });
        }).catch(error => {
           //console.log(error);
        });
        this.loadPage(0);
        
    }

    loadPage(offset) {
        axios.get('/noticias/20/' + offset).then(response => {
            this.setState({
                ...this.state,
                firstRun: false,
                notices: response.data,
            });
            window.scrollTo(0, 0)
        }).catch(error => {
           //console.log(error);
        });
    }

    onSelectHandler = (page) => {
        if (page === 1 && this.state.firstRun) return;
        this.loadPage((page - 1) * 20);
    }

    noticeClickHandler = (id) => {
        this.props.history.push({pathname: '/noticias/' + id});
    }

    render() {
        let nots = <Spinner />
        if (this.state.notices) {
            nots = (
                <div>
                    <h1>Últimas notícias do seu esporte preferido</h1>
                    <ListNotices notices={this.state.notices} clickhandler={this.noticeClickHandler} />
                    <Pagination items={this.state.items} initialPage={1} onChangePage={this.onSelectHandler} pageSize={20} />
                </div>
            )
        }

        return (
            <div className={classes.Notices}>
                {nots}
            </div>
        )
    }
}

export default withErrorHandler(Notices, axios);