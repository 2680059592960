import React from 'react';
import Media from "react-media";
import classes from './Ad.module.css';

const ad = (props) => {
    return (
        <div className={classes.Ad} onClick={props.onClick}>
            <a href={props.link} target="_blank" rel="noopener noreferrer">
                <Media query="(max-width: 599px)">
                    {matches =>
                        matches ? (
                            <img src={props.mobile} alt="ad" style={{ height: props.height }} />
                        ) : (
                                <img src={props.image} alt="ad" style={{ height: props.height }} />
                            )
                    }
                </Media>

            </a>
        </div>
    );
}

export default ad;