import React, {Component} from 'react';
import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import { Row, Col } from 'react-materialize';
import axios from '../../axios';
import classes from './Contact.module.css';

class Contact extends Component {
    state = {
        form: {
            nome: {
                elementtype: 'input',
                elementconfig: {
                    type: 'text',
                    label: 'Nome'
                },
                value: '',
                validation: {
                    required: true,
                },
                valid: false,
                touched: false
            },
            email: {
                elementtype: 'input',
                elementconfig: {
                    type: 'text',
                    label: 'E-Mail'
                },
                value: '',
                validation: {
                    required: true,
                },
                valid: false,
                touched: false
            },
            mensagem: {
                elementtype: 'input',
                elementconfig: {
                    type: 'text',
                    label: 'Mensagem'
                },
                value: '',
                validation: {
                    required: true,
                },
                valid: false,
                touched: false
            },
        },
        error: false,
        message: '',
        formSent: false
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedForm = {
            ...this.state.form
        }

        const updatedFormElement = { ...updatedForm[inputIdentifier] }

        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedForm[inputIdentifier] = updatedFormElement;
        let formIsValid = true;
        for (let inputIdentifiers in updatedForm) {
            formIsValid = updatedForm[inputIdentifiers].valid && formIsValid;
        }
        this.setState({ form: updatedForm, formIsValid: formIsValid });
    }

    checkValidity(value, rules) {
        let isValid = true;

        if (!rules) {
            return isValid;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid;
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid;
        }

        return isValid;
    }

    submitFormHandler = () => {
        const formData = new FormData();

        formData.append("nome", this.state.form['nome'].value);
        formData.append("email", this.state.form['email'].value);
        formData.append("mensagem", this.state.form['mensagem'].value);

        axios.post("/enviar-email", formData).then(resp => {
            this.setState({ 
                ...this.state,
                message: 'Contato enviado com sucesso! Em breve estaremos respondendo.', 
                error: false,
                formSent: true
            });
        }).catch(err => {
            this.setState({ message: 'Erro ao enviar a mensagem. Tente novamente mais tarde.', error: true})
        });
    }

    render(){
        const formElementsArray = [];

        for (let key in this.state.form) {
            formElementsArray.push({
                id: key,
                config: this.state.form[key]
            });
        }

        let form = (
            <form>
                {formElementsArray.map(formElement => (
                    <Input key={formElement.id}
                        elementtype={formElement.config.elementtype}
                        elementconfig={formElement.config.elementconfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        mask={formElement.config.mask}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)} />
                ))}
            </form>
        );

        return(
            <div className={classes.Contact}>
                <h1>Entre em contato!</h1>
                <Row>
                    <Col s={12} m={9}>
                        {form}
                    </Col>
                </Row>
                <h2 style={{color: this.state.error ? 'red' : 'green'}}>{this.state.message}</h2>
                <Button disabled={!this.state.formIsValid || this.state.formSent} onclick={this.submitFormHandler}>Enviar</Button>
            </div>
        )
    }
}

export default Contact;