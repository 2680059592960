import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

const tinyEditor = (props) => {
    return (
        <Editor
            apiKey="i8krooxmv5wl1hauxq24ae3kwgsgui3bctrdkiao8ku3ca3c"
            init={{ plugins: 'link table' }}
            value={props.data} 
            onEditorChange={props.onChange}
      />
    )
}

export default tinyEditor;