import React, { Component } from 'react';
import classes from './Gallery.module.css';
import Images from '../../components/Gallery/Images/Images';
import Pagination from '../../components/UI/Pagination/Pagination';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import axios from '../../axios';
import Spinner from '../../components/UI/Spinner/Spinner';

class Gallery extends Component {
    state = {
        firstRun: true,
        gallerys: null,
        items: 100
    }

    chunk(arr, len) {

        var chunks = [],
            i = 0,
            n = arr.length;

        while (i < n) {
            chunks.push(arr.slice(i, i += len));
        }

        return chunks;
    }

    componentDidMount(){
        axios.get('/totalgaleria').then(response => {
            this.setState({
                ...this.state,
                items: parseInt(response.data.count, 10)
            });
        }).catch(error => {
           //console.log(error);
        });
        this.loadPage(0);
    }


    loadPage(offset) {
        axios.get('/galerias/12/' + offset).then(response => {
            this.setState({
                ...this.state,
                firstRun: false,
                gallerys: response.data,
            });
            window.scrollTo(0, 0)
        }).catch(error => {
           //console.log(error);
        });
    }

    onSelectHandler = (page) => {
        if (page === 1 && this.state.firstRun) return;
        this.loadPage((page - 1) * 12);
    }

    imageClickHandler = (id) => {
        this.props.history.push({pathname: '/galerias/' + id});
    }

    render() {
        let images = <Spinner />;
        if (this.state.gallerys) {
            images = (
                <div>
                    <Images gallerys={this.chunk(this.state.gallerys, 3)} clickhandler={this.imageClickHandler}/>
                    <Pagination items={this.state.items} initialPage={1} onChangePage={this.onSelectHandler} pageSize={12} />
                </div>
            );

        }

        return (
            <div className={classes.Gallery}>
                {images}
            </div>
        )
    }
}

export default withErrorHandler(Gallery, axios);