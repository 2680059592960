import React from 'react';
import classes from './Notice.module.css';

const notice = (props) => {
    return (
        <div className={classes.Notice}>
            <p onClick={() => props.clickhandler(props.id)}>{props.title}</p>
        </div>
    )
}

export default notice;