export const NOTICE_IMG_PATH =
  "https://rsesportes1.websiteseguro.com/upload/noticias/";
export const COLUNISTA_IMG_PATH =
  "https://rsesportes1.websiteseguro.com/upload/colunista/thumbs/";
export const GALERIA_IMG_PATH =
  "https://rsesportes1.websiteseguro.com/upload/galerias/thumbs/";
export const GALERIA_DETALHE_IMG_PATH =
  "https://rsesportes1.websiteseguro.com/upload/galerias/";
export const COLUNISTA_HEADER_PATH =
  "https://rsesportes1.websiteseguro.com/upload/colunista/";

/*export const NOTICE_IMG_PATH = "http://localhost:8081/upload/noticias/";
export const COLUNISTA_IMG_PATH = "http://localhost:8081/upload/colunista/thumbs/";
export const GALERIA_IMG_PATH = "http://localhost:8081/upload/galerias/thumbs/";
export const GALERIA_DETALHE_IMG_PATH = "http://localhost:8081/upload/galerias/";
export const COLUNISTA_HEADER_PATH = "http://localhost:8081/upload/colunista/";*/
