import React, { Component } from 'react';
import Button from '../../../components/UI/Button/Button';
import axios from '../../../axios';
import Spinner from '../../../components/UI/Spinner/Spinner';
import ItemListSubject from '../../../components/Panel/ItemListSubject/ItemListSubject';
import { Modal } from 'react-materialize';
import Cookies from 'universal-cookie';

import classes  from './Column.module.css';

class Column extends Component {

    state = {
        columns: null,
        firstRun: true,
        idSelected: 0,
        showModal: false
    }

    componentDidMount() {
        
        this.loadPage();
    }

    loadPage() {
        const cookies = new Cookies();

        axios.get('/painel/colunas', { headers: {'J-Token': cookies.get("token")} }).then(response => {
            this.setState({
                ...this.state,
                firstRun: false,
                columns: response.data,
            });
            window.scrollTo(0, 0)
        }).catch(error => {
           //console.log(error);
        });
    }

    editClickHandler = (id) => {
        this.props.history.push({ pathname: '/painel/colunas/' + id });
    }

    deleteClickHandler = (id) => {
        this.setState({
            ...this.state,
            idSelected: id,
            showModal: true
        });

    }

    onModalClickDelete = () => {
        const cookies = new Cookies();

        axios.delete('/painel/coluna/' + this.state.idSelected, { headers: {'J-Token': cookies.get("token")} }).then(response => {
            this.setState({
                ...this.state,
                columns: null,
                idSelected: 0,
                showModal: false
            });

            this.loadPage(0);
        }).catch(err => {
            if (err.response.status === 401) {
                this.props.history.push({ pathname: '/painel/login' });
            }
        });
    }

    onModalClickCancel = () => {
        this.setState({
            ...this.state,
            idSelected: 0,
            showModal: false
        });
    }

    onAddHandler = () => {
        this.props.history.push({ pathname: '/painel/colunas/0' });
    }

    render(){
        let cols = <Spinner />;
        if (this.state.columns) {
            cols = this.state.columns.map(col => (
                <ItemListSubject key={col.id} id={col.id} title={col.coluna} name={col.user} edithandler={this.editClickHandler} deletehandler={this.deleteClickHandler} />
            ));
        }

        return (
            <div className={classes.Column}>
                <Modal
                    modalOptions={{
                        dismissible: false
                    }}
                    actions={
                        <div>
                            <Button onclick={this.onModalClickCancel}>Não</Button>
                            <Button onclick={this.onModalClickDelete}>Sim</Button>
                        </div>
                    }
                    open={this.state.showModal}
                    header='Atenção'>
                    Deseja realmente apagar a coluna?
                </Modal>

                <Button onclick={this.onAddHandler}>Adicionar coluna</Button>
                <div style={{ border: '1px solid lightgrey', margin: '20px 0', borderRadius: '5px' }}>
                    {cols}
                </div>
            </div>
        )
    }
}

export default Column;