import React, {Component} from 'react';

class Radios extends Component {
    render(){
        return(
            <div></div>
        )
    }
}

export default Radios;