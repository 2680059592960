import React from 'react';
import classes from './Share.module.css';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    TelegramShareButton,
    TelegramIcon,
    WhatsappShareButton,
    WhatsappIcon
  } from 'react-share';

const share = (props) => {
    return (
        <div className={classes.Share}>
            <FacebookShareButton quote={props.title} url={props.url}>
                <FacebookIcon size={32} round={true}  />
            </FacebookShareButton>
            <TwitterShareButton title={props.title} url={props.url}>
                <TwitterIcon size={32} round={true}  />
            </TwitterShareButton>
            <TelegramShareButton title={props.title} url={props.url}>
                <TelegramIcon size={32} round={true}  />
            </TelegramShareButton>
            <WhatsappShareButton title={props.title} url={props.url}>
                <WhatsappIcon size={32} round={true}  />
            </WhatsappShareButton>
        </div>
    )
}

export default share;