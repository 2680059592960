import React, {Component} from 'react';
import classes from './Index.module.css';

class Index extends Component {
    render(){
        return(
            <div className={classes.Index}>INDEX</div>
        )
    }
}

export default Index;