import React from 'react';
import Image from './Image/Image';
import { Col, Row } from 'react-materialize';
import Auxiliar from '../../../hoc/Auxiliar/Auxiliar';
import Ad from '../Ad/Ad';
import AdSense from 'react-adsense';

const gallery = (props) => {
    return (
        <Auxiliar>
            <Row>
                <Image image={props.imgs[0]} clickhandler={props.galleryclickhandler} />
            </Row>
            <Row style={{marginBottom: 0}}>
                <Col style={{marginLeft: 0}} s={6}>
                    {/*<Info infos={props.infos}/>*/}
                    {/* { props.ad ? <Ad link={props.ad.link} image={props.ad.image} mobile={props.ad.mobile} height="250px" /> : null }  */}
{/* 
                    <AdSense.Google
                        client='ca-pub-3674540814826814'
                        slot='4350879756'
                        style={{ display: 'block' }}
                        format='fluid'
                        responsive='true'/> */}
                </Col>
                <Col style={{paddingRight: 0}} s={6}>
                    <Row><Image image={props.imgs[1]} clickhandler={props.galleryclickhandler}/></Row>
                    <Row><Image image={props.imgs[2]} clickhandler={props.galleryclickhandler}/></Row>
                </Col>
            </Row>
        </Auxiliar>
    );
}

export default gallery;