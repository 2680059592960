import React, { Component } from 'react';
import Button from '../../../components/UI/Button/Button';
import axios from '../../../axios';
import Pagination from '../../../components/UI/Pagination/Pagination';
import Spinner from '../../../components/UI/Spinner/Spinner';
import ItemListSubject from '../../../components/Panel/ItemListSubject/ItemListSubject';
import { Modal } from 'react-materialize';
import Cookies from 'universal-cookie';
import classes from './Subject.module.css';

class Subject extends Component {
    state = {
        subjects: null,
        firstRun: true,
        items: 0,
        idSelected: 0,
        showModal: false
    }

    componentDidMount() {
        const cookies = new Cookies();

        axios.get('/painel/totalmateria', { headers: {'J-Token': cookies.get("token")} }).then(response => {
            this.setState({
                ...this.state,
                items: parseInt(response.data.count, 10)
            });
        }).catch(error => {
           //console.log(error);
        });
        this.loadPage(0);
    }

    loadPage(offset) {
        const cookies = new Cookies();

        axios.get('/painel/materias/20/' + offset, { headers: {'J-Token': cookies.get("token")} }).then(response => {
            this.setState({
                ...this.state,
                firstRun: false,
                subjects: response.data,
            });
            window.scrollTo(0, 0)
        }).catch(error => {
           //console.log(error);
        });
    }

    onSelectHandler = (page) => {
        if (page === 1 && this.state.firstRun) return;
        this.loadPage((page - 1) * 20);
    }

    editClickHandler = (id) => {
        this.props.history.push({ pathname: '/painel/materias/' + id });
    }

    deleteClickHandler = (id) => {
        this.setState({
            ...this.state,
            idSelected: id,
            showModal: true
        });

    }

    onModalClickDelete = () => {
        const cookies = new Cookies();

        axios.delete('/painel/materia/' + this.state.idSelected, { headers: {'J-Token': cookies.get("token")} }).then(response => {
            this.setState({
                ...this.state,
                subjects: null,
                idSelected: 0,
                showModal: false
            });

            this.loadPage(0);
        }).catch(err => {
            if (err.response.status === 401) {
                this.props.history.push({ pathname: '/painel/login' });
            }
        });
    }

    onModalClickCancel = () => {
        this.setState({
            ...this.state,
            idSelected: 0,
            showModal: false
        });
    }

    onAddHandler = () => {
        this.props.history.push({ pathname: '/painel/materias/0' });
    }

    render() {
        let nots = <Spinner />;
        if (this.state.subjects) {
            nots = this.state.subjects.map(not => (
                <ItemListSubject key={not.id} id={not.id} title={not.titulo} name={not.nome} edithandler={this.editClickHandler} deletehandler={this.deleteClickHandler} />
            ));
        }

        return (
            <div className={classes.Subject}>
                <Modal
                    modalOptions={{
                        dismissible: false
                    }}
                    actions={
                        <div>
                            <Button onclick={this.onModalClickCancel}>Não</Button>
                            <Button onclick={this.onModalClickDelete}>Sim</Button>
                        </div>
                    }
                    open={this.state.showModal}
                    header='Atenção'>
                    Deseja realmente apagar a matéria?
                </Modal>

                <Button onclick={this.onAddHandler}>Adicionar matéria</Button>
                <div style={{ border: '1px solid lightgrey', margin: '20px 0', borderRadius: '5px' }}>
                    {nots}
                    {this.state.items > 0 ? <Pagination items={this.state.items} initialPage={1} onChangePage={this.onSelectHandler} pageSize={20} /> : null }
                </div>
            </div>
        )
    }
}

export default Subject;