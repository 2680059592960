import React from 'react';
import { Col, Row } from 'react-materialize';
import Item from './Item/Item';

const images = (props) => {

    return (
        <div>
            {
                props.gallerys.map(gal => (
                    <Row key={Math.random()}>
                        {
                            gal.map(gal2 => (
                                <Col s={12} m={4} key={gal2.id}>
                                    <Item id={gal2.id} title={gal2.title} image={gal2.image} clickhandler={props.clickhandler}/>
                                </Col>
                            ))
                        }
                    </Row>
                ))
                /*props.gallerys.map(gal => (
                    <Col s={12} m={4} key={gal.id}>
                        <Item title={gal.title} image={gal.image}/>
                    </Col>
                ))*/
            }
        </div>
    )
}

export default images;