import React, { Component } from 'react';

import Auxiliar from '../../hoc/Auxiliar/Auxiliar';
import Toolbar from '../../components/Nav/Toolbar/Toolbar';
import SideDrawer from '../../components/Nav/SideDrawer/SideDrawer';
import { Switch, Route, Redirect } from 'react-router';
import classes from './AdminLayout.module.css';
import Gallery from '../../containers/Panel/Gallery/Gallery';
import User from '../../containers/Panel/User/User';
import GalleryEdit from '../../containers/Panel/Gallery/Edit/Edit';
import Index from '../../containers/Panel/Index/Index';
import Notice from '../../containers/Panel/Notice/Notice';
import NoticeEdit from '../../containers/Panel/Notice/Edit/Edit';
import UserEdit from '../../containers/Panel/User/Edit/Edit';
import Subject from '../../containers/Panel/Subject/Subject';
import SubjectEdit from '../../containers/Panel/Subject/Edit/Edit';
import Column from '../../containers/Panel/Column/Column';
import ColumnEdit from '../../containers/Panel/Column/Edit/Edit';
import Ads from '../../containers/Panel/Ads/Ads';
import AdsEdit from '../../containers/Panel/Ads/Edit/Edit';
import Cookies from 'universal-cookie';
import axios from '../../axios';

class AdminLayout extends Component {
    state = {
        COMPONENT_MAP: {
            '/painel/galerias': Gallery,
            '/painel/noticias': Notice,
            '/painel/usuarios': User,
            '/painel/materias': Subject,
            '/painel/colunas': Column,
            '/painel/ads': Ads,
            '/painel/galerias/:id': GalleryEdit,
            '/painel/noticias/:id': NoticeEdit,
            '/painel/usuarios/:id': UserEdit,
            '/painel/materias/:id': SubjectEdit,
            '/painel/colunas/:id': ColumnEdit,
            '/painel/ads/:id': AdsEdit,
        },
        showSideDrawer: false,
        isWaitingAuthentication: true,
        routes: null
    }

    componentWillMount() {
        const cookies = new Cookies();
        if (!cookies.get("token"))
            this.props.history.push({ pathname: '/painel/login' });
    }

    componentDidMount(){
        const cookies = new Cookies();

        if (cookies.get("token") && this.state.isWaitingAuthentication){
            axios.get("/painel/permissoes", { headers: {'J-Token': cookies.get("token")} }).then(resp=>{
                this.setState({routes: resp.data, isWaitingAuthentication: false});
               //console.log(resp);
            }).catch(err=>{
               //console.log(err);
                if (err.response.status === 401) {
                    this.props.history.push({ pathname: '/painel/login' });
                }
            });

        } 
    }

    sideDrawerClosedHandler = () => {
        this.setState({ showSideDrawer: false });
    }

    sideDrawerToggleHandler = () => {
        this.setState((prevState) => {
            return { showSideDrawer: !prevState.showSideDrawer }
        });
    }

    render() {
        let routes = (
            <Switch><Redirect to="/painel/login"></Redirect></Switch>
        );

        if(this.state.isWaitingAuthentication){
            routes = (<Switch></Switch>);
        } else {
            if(this.state.routes){
                let perm = this.state.routes.map(p => (
                    <Route key={p.path} path={p.path} exact component={this.state.COMPONENT_MAP[p.path]} />
                ));
                routes = (
                    <Switch>
                        <Route path="/painel" exact component={Index}/>
                        {perm}
                        <Redirect to="/painel"></Redirect>
                    </Switch>
                );
            }
        }

        return (
            <Auxiliar>
                <Toolbar admin routes={this.state.routes} drawerToggleClicked={this.sideDrawerToggleHandler} />
                <SideDrawer open={this.state.showSideDrawer} closed={this.sideDrawerClosedHandler} />
                <main className={classes.container}>
                    {routes}
                </main>
            </Auxiliar>
        )
    }
}

export default AdminLayout;