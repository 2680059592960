import React, { Component } from 'react';
import { Row, Col } from 'react-materialize';
import Input from '../../../../components/UI/Input/Input';
import Button from '../../../../components/UI/Button/Button';
import axios from '../../../../axios';
import Cookies from 'universal-cookie';
import NewFileUploader from '../../../../components/UI/NewFileUploader/NewFileUploader';
import classes from './Edit.module.css';

class Edit extends Component {
    state = {
        pc: null,
        pcPreview: null,
        cel: null,
        celPreview: null,
        id: 0,
        form: null,
        formIsValid: false,
    }

    componentDidMount() {
        const cookies = new Cookies();

        if (this.props.match.params.id > 0) {

            axios.get('/painel/prop/' + this.props.match.params.id, { headers: { 'J-Token': cookies.get("token") } }).then(response2 => {
                //console.log(response2.data);
                var dataValidade = new Date(response2.data.data_validade);
                this.setState({
                    ...this.state,
                    pcPreview: response2.data.imagem,
                    celPreview: response2.data.imagem_mobile,
                    id: this.props.match.params.id,
                    formIsValid: true,
                    form: {
                        nome: {
                            elementtype: 'input',
                            elementconfig: {
                                type: 'text',
                                label: 'Nome'
                            },
                            value: response2.data.nome,
                            validation: {
                                required: true,
                            },
                            valid: true,
                            touched: true
                        },
                        link: {
                            elementtype: 'input',
                            elementconfig: {
                                type: 'text',
                                label: 'Link'
                            },
                            value: response2.data.link,
                            validation: {
                                required: false,
                            },
                            valid: true,
                            touched: true
                        },
                        data: {
                            elementtype: 'input',
                            elementconfig: {
                                type: 'text',
                                label: 'Data de validade'
                            },
                            value: dataValidade.toLocaleDateString(),
                            validation: {
                                required: true,
                                minLength: 10
                            },
                            valid: true,
                            touched: true,
                            mask: '99/99/9999'
                        },
                        tipo: {
                            elementtype: 'select',
                            elementconfig: {
                                options: [
                                    { value: 'BIG', displayValue: 'Grande' },
                                    { value: 'BOT', displayValue: 'Inferior' },
                                    { value: 'MID', displayValue: 'Sobre materias' },
                                    { value: 'VIDEO', displayValue: 'Sob o video' },
                                    { value: 'NEWS', displayValue: 'Noticia' },
                                ],
                                label: 'Tipo'
                            },
                            value: response2.data.tipo,
                            valid: true
                        }
                    },
                });

            }).catch(error => {
               //console.log(error);
            });
        } else {
            this.setState({
                ...this.state.form,
                form: {
                    nome: {
                        elementtype: 'input',
                        elementconfig: {
                            type: 'text',
                            label: 'Nome'
                        },
                        value: '',
                        validation: {
                            required: true,
                        },
                        valid: false,
                        touched: false
                    },
                    link: {
                        elementtype: 'input',
                        elementconfig: {
                            type: 'text',
                            label: 'Link'
                        },
                        value: '',
                        validation: {
                            required: false,
                        },
                        valid: true,
                        touched: false
                    },
                    data: {
                        elementtype: 'input',
                        elementconfig: {
                            type: 'text',
                            label: 'Data de validade'
                        },
                        value: '',
                        validation: {
                            required: true,
                            minLength: 10
                        },
                        valid: false,
                        touched: false,
                        mask: '99/99/9999'
                    },
                    tipo: {
                        elementtype: 'select',
                        elementconfig: {
                            options: [
                                { value: 'BIG', displayValue: 'Grande' },
                                { value: 'BOT', displayValue: 'Inferior' },
                                { value: 'MID', displayValue: 'Sobre materias' },
                                { value: 'VIDEO', displayValue: 'Sob o video' },
                                { value: 'NEWS', displayValue: 'Noticia' },
                            ],
                            label: 'Tipo'
                        },
                        value: 'BIG',
                        valid: true
                    }
                },
            });
        }

    }

    onChangePc = (evt) => {
        const files = evt.target.files;
        this.setState({ pcPreview: URL.createObjectURL(files[0]), pc: files[0] });
        //console.log(files);
    }

    onChangeCel = (evt) => {
        const files = evt.target.files;
        this.setState({ celPreview: URL.createObjectURL(files[0]), cel: files[0] });
        //console.log(files);
    }

    submitFormHandler = () => {
        this.sendBasicData();
    }

    sendBasicData = () => {
        const formData = new FormData();

        let date = new Date();

        formData.append("id", this.state.id);
        formData.append("nome", this.state.form['nome'].value);
        formData.append("link", this.state.form['link'].value);
        formData.append("tipo", this.state.form['tipo'].value);
        formData.append("data_validade", this.state.form['data'].value);
        formData.append("pc", this.state.pc);
        formData.append("cel", this.state.cel);

        if(this.state.pc)
            formData.append("namepc", this.state.pc.name.substring(0, this.state.pc.name.lastIndexOf('.')).replace(/\s/g, "") + date.getTime() + '.jpg');
        
        if(this.state.cel)
            formData.append("namecel", this.state.cel.name.substring(0, this.state.cel.name.lastIndexOf('.')).replace(/\s/g, "") + date.getTime() + '.jpg');

        //console.log(formData);

        const cookies = new Cookies();

        axios.post("/painel/prop", formData, { headers: { 'J-Token': cookies.get("token") } }).then(resp => {
            //console.log(resp);
            this.props.history.push({ pathname: '/painel/ads' });
            //this.sendGallery(resp.data.directory);
        }).catch(err => {
            if (err.response.status === 401) {
                this.props.history.push({ pathname: '/painel/login' });
            }
        });
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedForm = {
            ...this.state.form
        }

        const updatedFormElement = { ...updatedForm[inputIdentifier] }

        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedForm[inputIdentifier] = updatedFormElement;
        let formIsValid = true;
        for (let inputIdentifiers in updatedForm) {
            formIsValid = updatedForm[inputIdentifiers].valid && formIsValid;
        }
        this.setState({ form: updatedForm, formIsValid: formIsValid });
       //console.log(updatedForm);
    }

    checkValidity(value, rules) {
        let isValid = true;

        if (!rules) {
            return isValid;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid;
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid;
        }

        return isValid;
    }

    render() {
        const formElementsArray = [];

        for (let key in this.state.form) {
            formElementsArray.push({
                id: key,
                config: this.state.form[key]
            });
        }

        let form = (
            <form>
                {formElementsArray.map(formElement => (
                    <Input key={formElement.id}
                        elementtype={formElement.config.elementtype}
                        elementconfig={formElement.config.elementconfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        mask={formElement.config.mask}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)} />
                ))}
            </form>
        );

        var pcImage = {
            backgroundImage: 'url(' + this.state.pcPreview + ')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            width: '360px',
            height: '180px'
        }

        var celImage = {
            backgroundImage: 'url(' + this.state.celPreview + ')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            width: '360px',
            height: '180px'
        }

        return (
            <div className={classes.Edit}>

                <Row>
                    <Col m={9} s={12}>
                        <h1>Dados ad</h1>
                        {form}
                    </Col>
                </Row>
                <Row>
                    <h1>Imagem PC</h1>
                    <div style={pcImage}>
                        <NewFileUploader onChange={this.onChangePc} />
                    </div>
                </Row>
                <Row>
                    <h1>Imagem Celular</h1>
                    <div style={celImage}>
                        <NewFileUploader onChange={this.onChangeCel} />
                    </div>
                </Row>
                <Row>
                    <Button disabled={!this.state.formIsValid || (!this.state.pc && this.state.id === 0) || (!this.state.cel && this.state.id === 0)} onclick={this.submitFormHandler}>Enviar</Button>
                </Row>
            </div>
        )
    }
}

export default Edit;