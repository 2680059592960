import React from 'react';
import { Row, Col, Icon } from 'react-materialize';

import classes from './ItemListSubject.module.css';

const itemListSubject = (props) => {
    return (
        <Row className={classes.ItemListSubject}>
            <Col m={7} s={6}>
                <p>{props.title}</p>
            </Col>
            <Col m={3} s={3}>
                <p>{props.name}</p>
            </Col>
            <Col style={{textAlign: 'right'}} m={2} s={3}>
                <div style={{display: 'inline-block'}} onClick={() => props.edithandler(props.id)}><Icon>create</Icon></div>
                <div style={{display: 'inline-block'}} onClick={() => props.deletehandler(props.id)}><Icon>clear</Icon></div>
            </Col>
        </Row>
    )
}

export default itemListSubject;