import React from 'react';
import classes from './Logo.module.css';
import Logout from '../../../assets/images/logout.png';

const logo = (props) => (
    <div className={classes.Logo} >
        <img src={props.img} alt="logo" onClick={() => window.location.replace( props.admin ? '/painel' : '/')} />
        {props.admin && <div className={classes.Logout}>
            <img src={Logout} alt="" onClick={() => window.location.replace( '/painel/login' )} />
        </div>}
    </div>
);

export default logo;