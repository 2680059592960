import React from 'react';
import { Col, Row } from 'react-materialize';
import * as defaults from '../../../../../defaults';
import classes from './Notice.module.css';

const notice = (props) => {
    return (
        <Row onClick={() => props.clickhandler(props.id)} className={classes.Notice} style={{cursor: 'pointer'}}>
            <Col s={6} style={{ padding: 0 }}>
                <div className={classes.Rectangle} style={{ backgroundImage: "url(" + defaults.NOTICE_IMG_PATH + props.image + ")" }}></div>
            </Col>
            <Col s={6}>
                <h1>{props.title}</h1>
                <p>{props.body}</p>
            </Col>
        </Row>
    );
}

export default notice;