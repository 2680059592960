import React from 'react';
import { FacebookProvider, Page } from 'react-facebook';
import Media from "react-media";

const facebook = () => {
    return (
        <FacebookProvider appId="693484214039945">
            <Media query={{ maxWidth: 600 }}>
                {matches =>
                    matches ? (
                        <Page height={780} width={500} href="https://www.facebook.com/rsesportes.com.br/" tabs="timeline" />
                    ) : (
                            <Media query={{ maxWidth: 992 }}>
                                {matches =>
                                    matches ? (
                                        <Page height={600} width={400} href="https://www.facebook.com/rsesportes.com.br/" tabs="timeline" />
                                    ) : (
                                            <Media query={{ maxWidth: 1200 }}>
                                                {matches =>
                                                    matches ? (
                                                        <Page height={750} width={600} href="https://www.facebook.com/rsesportes.com.br/" tabs="timeline" />
                                                    ) : (
                                                            <Page height={890} width={750} href="https://www.facebook.com/rsesportes.com.br/" tabs="timeline" />
                                                        )
                                                }
                                            </Media>
                                        )
                                }
                            </Media>
                        )
                }
            </Media>
        </FacebookProvider>
    );
}

export default facebook;