import React, { Component } from 'react';
import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import axios from '../../../axios';
import Cookies from 'universal-cookie';
import classes from './Login.module.css';

class Login extends Component {

    state = {
        form: {
            login: {
                elementtype: 'input',
                elementconfig: {
                    type: 'text',
                    label: 'Login'
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            },
            senha: {
                elementtype: 'input',
                elementconfig: {
                    type: 'password',
                    label: 'Senha'
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            },
        },
        formIsValid: false,
        sending: false,
        error: false,
        error_message: null
    }

    componentWillMount() {
        const cookies = new Cookies();
        cookies.remove("token", { path: '/' });
        cookies.remove("user", { path: '/' });
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedForm = {
            ...this.state.form
        }

        const updatedFormElement = { ...updatedForm[inputIdentifier] }

        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedForm[inputIdentifier] = updatedFormElement;
        let formIsValid = true;
        for (let inputIdentifiers in updatedForm) {
            formIsValid = updatedForm[inputIdentifiers].valid && formIsValid;
        }
        this.setState({ form: updatedForm, formIsValid: formIsValid });
    }

    checkValidity(value, rules) {
        let isValid = true;
        value = value.replace('_', '');

        if (!rules) {
            return isValid;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid;
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid;
        }

        return isValid;
    }

    loginHandler = () => {

        this.setState({ sending: true, error: false, error_message: null });

        const formData = new FormData();

        formData.append("usuario", this.state.form['login'].value);
        formData.append("senha", this.state.form['senha'].value);

        axios.post("/painel/login", formData, { headers: { 'J-Token': 'foobar' } }).then(resp => {

            if (resp.data.error) {
                this.setState({ sending: false, error: true, error_message: resp.data.message });
            } else {
                this.setState({ sending: false, error: false, error_message: null });
                const cookies = new Cookies();

                cookies.set('token', resp.data.token, { path: '/' });
                cookies.set('user', resp.data.user, { path: '/' });

                this.props.history.push({ pathname: '/painel' });
            }
        }).catch(err => {
            this.setState({ sending: false });
        });
    }

    render() {

        const formElementsArray = [];

        for (let key in this.state.form) {
            formElementsArray.push({
                id: key,
                config: this.state.form[key]
            });
        }

        let form = formElementsArray.map(formElement => (
            <Input key={formElement.id}
                elementtype={formElement.config.elementtype}
                elementconfig={formElement.config.elementconfig}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                mask={formElement.config.mask}
                changed={(event) => this.inputChangedHandler(event, formElement.id)} />
        ));

        return (
            <div className={classes.Login}>
                <div className={[classes.card, classes.card3].join(' ')}>
                    <form>
                        {form}
                        <p hidden={!this.state.error}>{this.state.error_message}</p>
                        <Button disabled={!this.state.formIsValid || this.state.sending} onclick={this.loginHandler}>Login</Button>
                    </form>
                </div>
            </div>
        )
    }
}

export default Login;