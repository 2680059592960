import React from 'react';
import classes from './NavItensAdm.module.css';
import NavItem from './NavItem/NavItem';

const navItensAdm = (props) => {
    let itens = null;

    if (props.routes){
        itens = props.routes.map(r =>  (
            r.isnavitem === '1' ? <NavItem key={r.path} link={r.path}>{r.nome}</NavItem> : null
        ));
    }

    return (
        <ul className={classes.NavItensAdm}>
            {itens}
        </ul>
    );
} 

export default navItensAdm;