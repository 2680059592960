import React from 'react';
import classes from './NavItens.module.css';
import NavItem from './NavItem/NavItem';

const navItens = () => (
    <ul className={classes.NavItens}>
        <NavItem link="/noticias">Noticias</NavItem>
        <NavItem link="/galerias">Galerias</NavItem>
        {/* <NavItem link="/colunistas">Colunistas</NavItem> */}
        <NavItem link="/contato">Contato</NavItem>
    </ul>
);

export default navItens;