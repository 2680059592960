import React from 'react';
import { Row } from 'react-materialize';
import classes from './Item.module.css';
import * as defaults from '../../../../defaults';

const item = (props) => {
    return (
        <div style={{cursor: 'pointer'}} className={classes.Item} onClick={() => props.clickhandler(props.id)}>
            <Row style={{marginBottom: 0}}>
                <div className={classes.Image} style={{ backgroundImage: "url(" + defaults.GALERIA_IMG_PATH + props.image + ")" }}></div>
                {/*<LazyImage placeholder={props.placeholder} src={props.image} />*/}
            </Row>
            <h1>{props.title}</h1>
        </div>
    )
}

export default item;