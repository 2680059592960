import React, { Component } from 'react';
import classes from './Columnist.module.css';
import { Row, Col } from 'react-materialize';
import renderHTML from 'react-render-html';
import * as defaults from '../../defaults';
import Spinner from '../../components/UI/Spinner/Spinner';
import axios from '../../axios';
import ListNotices from '../../components/Notices/Notices';

class Columnist extends Component {
    state = {
        colunistas: null,
        colunas: null
    }

    componentDidMount(){
        axios.get('/colunistas/10/0').then(response => {

            /*axios.get('/comentarios/5/0/8').then(response2 => {
                this.setState({
                    ...this.state,
                    colunista: response2.data,
                });
            }).catch(error => {
               //console.log(error);
            });*/
            this.setState({
                ...this.state,
                colunistas: response.data,
            });
        }).catch(error => {
           //console.log(error);
        });

        
    }

    columnClickHandler = (id) => {
        this.props.history.push({pathname: '/colunas/' + id});
    }

    render() {
        let colunistas = <Spinner/>;
        if (this.state.colunistas) {
            colunistas = this.state.colunistas.map(col => (
                <div key={col.id}>
                    <Row style={{ marginBottom: 0 }}>
                        <Col m={4} s={5}>
                            <h1>{col.coluna}</h1>
                        </Col>
                        <Col m={8} s={7}>
                            <h2>{col.name}</h2>
                            <h2>{col.mail}</h2>
                        </Col>
                    </Row>
                    <img src={defaults.COLUNISTA_HEADER_PATH + '/' + col.header} alt="colunista"/>
                    {renderHTML(col.body)}
                    {col.colunas && 
                        <ListNotices notices={col.colunas} clickhandler={this.columnClickHandler} />}
                    <hr/>
                </div>
            ))
        }

        return (
            <div className={classes.Columnist}>
                {colunistas}
            </div>
        )
    }
}

export default Columnist;