import React from 'react';
import YouTube from 'react-youtube';

const video = (props) => {
    const opts = {
        height: '300px',
        width: '100%',
        playerVars: { // https://developers.google.com/youtube/player_parameters
            autoplay: 0
        }
    };

    return (
        <YouTube
            videoId={props.videoId}
            opts={opts}
        />
    );
}

export default video;