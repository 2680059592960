import React, { Component } from 'react';
import { Row, Col, ProgressBar } from 'react-materialize';
import Input from '../../../../components/UI/Input/Input';
import Button from '../../../../components/UI/Button/Button';
import axios from '../../../../axios';
import Cookies from 'universal-cookie';
import NewFileUploader from '../../../../components/UI/NewFileUploader/NewFileUploader';
import * as defaults from '../../../../defaults';

import classes from './Edit.module.css';

class Edit extends Component {
    state = {
        id: 0,
        thumb: null,
        thumbPreview: null,
        gallery: null,
        galleryPreview: null,
        totalImages: 0,
        uploadedImages: 0,
        uploading: false,
        form: null,
        formIsValid: false,
        directory: null
    }

    componentDidMount() {
        if (this.props.match.params.id > 0) {
            const cookies = new Cookies();

            axios.get('/painel/galeria/' + this.props.match.params.id, { headers: { 'J-Token': cookies.get("token") } }).then(response => {
                var dataEvento = new Date(response.data.data);
                dataEvento.setDate(dataEvento.getDate() + 1);

                this.setState({
                    ...this.state,
                    thumbPreview: defaults.GALERIA_IMG_PATH + response.data.imagem,
                    id: this.props.match.params.id,
                    formIsValid: true,
                    directory: response.data.diretorio,
                    form: {
                        evento: {
                            elementtype: 'input',
                            elementconfig: {
                                type: 'text',
                                label: 'Evento'
                            },
                            value: response.data.evento,
                            validation: {
                                required: true
                            },
                            valid: true,
                            touched: true
                        },
                        lugar: {
                            elementtype: 'input',
                            elementconfig: {
                                type: 'text',
                                label: 'Lugar'
                            },
                            value: response.data.lugar,
                            validation: {
                                required: true
                            },
                            valid: true,
                            touched: true
                        },
                        data: {
                            elementtype: 'input',
                            elementconfig: {
                                type: 'text',
                                label: 'Data'
                            },
                            value: dataEvento.toLocaleDateString(),
                            validation: {
                                required: true,
                                minLength: 10
                            },
                            valid: true,
                            touched: true,
                            mask: '99/99/9999'
                        },
                    },
                });

            }).catch(error => {
               //console.log(error);
            });
        } else {
            this.setState({
                ...this.state.form,
                form: {
                    evento: {
                        elementtype: 'input',
                        elementconfig: {
                            type: 'text',
                            label: 'Evento'
                        },
                        value: '',
                        validation: {
                            required: true
                        },
                        valid: false,
                        touched: false
                    },
                    lugar: {
                        elementtype: 'input',
                        elementconfig: {
                            type: 'text',
                            label: 'Lugar'
                        },
                        value: '',
                        validation: {
                            required: true
                        },
                        valid: false,
                        touched: false
                    },
                    data: {
                        elementtype: 'input',
                        elementconfig: {
                            type: 'text',
                            label: 'Data'
                        },
                        value: '',
                        validation: {
                            required: true,
                            minLength: 10
                        },
                        valid: false,
                        touched: false,
                        mask: '99/99/9999'
                    },
                },
            });
        }
    }

    onChangeThumb = (evt) => {
        const files = evt.target.files;
        this.setState({ thumbPreview: URL.createObjectURL(files[0]), thumb: files[0] });
        //console.log(files[0]);
    }

    onChangeGallery = (evt) => {
        const files = evt.target.files;
        //this.setState({ thumbPreview: URL.createObjectURL(files[0]), thumb: files[0] });
        
        var allFiles = this.state.gallery;
        if (allFiles == null) {
            allFiles = Array.from(files);
        } else {
            allFiles = allFiles.concat(Array.from(files));
        }

        this.setState({
            ...this.state,
            gallery: allFiles
        });

        //console.log(this.state);
        //console.log(allFiles);
    }

    submitFormHandler = () => {
        //this.sendGallery('230820181656');
        this.sendBasicData();
    }

    sendBasicData = () => {
        const formData = new FormData();

        formData.append("id", this.state.id);
        formData.append("event", this.state.form['evento'].value);
        formData.append("place", this.state.form['lugar'].value);
        formData.append("date", this.state.form['data'].value);
        formData.append("thumb", this.state.thumb);

        //console.log(this.state);

        const cookies = new Cookies();

        axios.post("/painel/galeria", formData, { headers: {'J-Token': cookies.get("token")} }).then(resp=> {
            //console.log(resp);
            if(this.state.gallery)
                this.sendGallery(this.state.id > 0 ? this.state.directory : resp.data.directory);
            else
                this.props.history.push({ pathname: '/painel/galerias' });

        }).catch(err => {
            if (err.response.status === 401) {
                this.props.history.push({ pathname: '/painel/login' });
            }
        });
    }

    sendGallery = (directory) => {
        const cookies = new Cookies();

        if (this.state.gallery){
            this.setState({totalImages: this.state.gallery.length, uploading: true})
            const uploaders = this.state.gallery.map(file => {

                const config = { headers: { 'Content-Type': 'multipart/form-data', 'J-Token': cookies.get("token") } };
                const formData = new FormData();
                formData.append("file", file);
                formData.append("directory", directory);
                formData.append("filename", file.name);

                return axios.post("/painel/galeria_imagens", formData, config).then(response => {
                    this.setState({uploadedImages: this.state.uploadedImages+1})                    
                }).catch(err => {
                    if (err.response.status === 401) {
                        this.props.history.push({ pathname: '/painel/login' });
                    }
                });
            });
    
            Promise.all(uploaders).then(() => {
                //window.location.reload();
                this.props.history.push({ pathname: '/painel/galerias' });
            });
        }
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedForm = {
            ...this.state.form
        }

        const updatedFormElement = { ...updatedForm[inputIdentifier] }

        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedForm[inputIdentifier] = updatedFormElement;
        let formIsValid = true;
        for (let inputIdentifiers in updatedForm) {
            formIsValid = updatedForm[inputIdentifiers].valid && formIsValid;
        }
        this.setState({ form: updatedForm, formIsValid: formIsValid });
    }

    checkValidity(value, rules) {
        let isValid = true;
        value = value.replace('_','');

        if (!rules) {
            return isValid;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid;
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid;
        }

        return isValid;
    }

    render() {
        const formElementsArray = [];

        for (let key in this.state.form) {
            formElementsArray.push({
                id: key,
                config: this.state.form[key]
            });
        }

        let form = (
            <form>
                {formElementsArray.map(formElement => (
                    <Input key={formElement.id}
                        elementtype={formElement.config.elementtype}
                        elementconfig={formElement.config.elementconfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        mask={formElement.config.mask}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)} />
                ))}
            </form>
        );

        let fotos = null;
        if(this.state.gallery){
           //console.log(this.state.gallery);
            fotos = this.state.gallery.map((file) => (
                <Col key={file.name + Math.random()} style={{ margin: '10px 0' }}>
                    <div className={classes.Thumb}>
                        <img src={URL.createObjectURL(file)} alt="img" />
                    </div>
                </Col>
            ));
        }

        var thumbImage = {
            backgroundImage: 'url(' + this.state.thumbPreview + ')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: '180px',
            height: '180px'
        }

        return (
            <div className={classes.Edit}>
                <Row>
                    <Col m={9} s={12}>
                        <h1>Dados da galeria</h1>
                        {form}
                    </Col>
                    <Col m={3} s={12}>
                        <h1>Thumb</h1>
                        <div style={thumbImage}>
                            <NewFileUploader multiple={false} onChange={this.onChangeThumb} />
                        </div>
                    </Col>
                </Row>
                <h1>Fotos</h1>
                <Row style={{ border: '1px solid lightgrey', borderRadius: '10px' }}>
                    <Col style={{ margin: '10px 0' }}>
                    <div className={classes.Thumb}>
                        <NewFileUploader multiple={true} onChange={this.onChangeGallery} />
                    </div>
                    </Col>
                    {fotos}
                </Row>
                <Row>
                    <Col s={2}>
                        <Button disabled={!this.state.formIsValid || (!this.state.thumb && this.state.id === 0) || (!this.state.gallery && this.state.id === 0)} onclick={this.submitFormHandler}>Enviar</Button>
                    </Col>
                    <Col s={10}>
                        {this.state.uploading ? 
                        <ProgressBar progress={100 * this.state.uploadedImages / this.state.totalImages}/> : null}
                    </Col>

                </Row>
            </div>
        )
    }
}

export default Edit;