import React, { Component } from 'react';
import Spinner from '../../../components/UI/Spinner/Spinner';
import axios from '../../../axios';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import renderHTML from 'react-render-html';
import Share from '../../../components/UI/Share/Share';
import {Row, Col} from 'react-materialize';
import DetailWithAd from '../../../components/Notices/DetailWithAds/DetailWithAds';
//import ReactGA from 'react-ga';

import classes from './Column.module.css';

class Column extends Component {
    state = {
        coluna: null,
        ads: null
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            axios.get('/coluna/' + this.props.match.params.id).then(response => {
                this.setState({
                    ...this.state,
                    coluna: response.data
                });

                if(response.data.ads){
                    axios.get('/detal_props/' + response.data.ads).then(response => {
                        this.setState({
                            ...this.state,
                            ads: response.data
                        });
    
                    }).catch(error => {
                       //console.log(error);
                    });
                }

            }).catch(error => {
               //console.log(error);
            });
        }
    }

    onClickAd = (id) => {
        /*ReactGA.event({
            category: 'Banner',
            action: 'Click em um ad na matéria',
            label: 'id: ' + id
          });*/
    }

    render() {
        let col = <Spinner />
        if (this.state.coluna) {
            col = (<div>
                <h1>{this.state.coluna.title}</h1>
                <Share title={this.state.coluna.title} url={window.location.href}/>
                <Row>
                    <Col m={this.state.ads ? 10 : 12}>
                        {renderHTML(this.state.coluna.body)}
                    </Col>
                    {this.state.ads &&
                        <Col m={2}>
                            <DetailWithAd ads={this.state.ads} onClick={this.onClickAd}/>
                        </Col>
                    }

                </Row>
            </div>)
        }

        return (
            <div className={classes.Column}>
                {col}
            </div>
        )
    }
}

export default withErrorHandler(Column, axios);