import React, { Component } from 'react';
import { Row, Col } from 'react-materialize';
import Input from '../../../../components/UI/Input/Input';
import Button from '../../../../components/UI/Button/Button';
import axios from '../../../../axios';
import Cookies from 'universal-cookie';
import classes from './Edit.module.css';
import NewFileUploader from '../../../../components/UI/NewFileUploader/NewFileUploader';
import TinyEditor from '../../../../components/Panel/TinyEditor/TinyEditor';
import * as defaults from '../../../../defaults';
import { Multiselect } from 'react-widgets'
import 'react-widgets/dist/css/react-widgets.css';

class Edit extends Component {
    state = {
        thumb: null,
        thumbPreview: null,
        id: 0,
        form: null,
        formIsValid: false,
        text: '',
        ads: null,
        selectedAds: null
    }

    componentDidMount() {
        const cookies = new Cookies();

        axios.get('/painel/props_news', { headers: { 'J-Token': cookies.get("token") } }).then(resp => {
            if (this.props.match.params.id > 0) {

                axios.get('/painel/noticia/' + this.props.match.params.id, { headers: { 'J-Token': cookies.get("token") } }).then(response => {
                   //console.log(response.data);
                    this.setState({
                        ...this.state,
                        thumbPreview: defaults.NOTICE_IMG_PATH + response.data.imagem,
                        text: response.data.text,
                        id: this.props.match.params.id,
                        formIsValid: true,
                        ads: resp.data,
                        selectedAds: response.data.ads ? response.data.ads.split(',').map(String) : [],
                        form: {
                            titulo: {
                                elementtype: 'input',
                                elementconfig: {
                                    type: 'text',
                                    label: 'Título'
                                },
                                value: response.data.title,
                                validation: {
                                    required: true,
                                },
                                valid: true,
                                touched: true
                            },
                            link: {
                                elementtype: 'input',
                                elementconfig: {
                                    type: 'text',
                                    label: 'Link'
                                },
                                value: response.data.link,
                                validation: {
                                    required: false,
                                },
                                valid: true,
                                touched: true,
    
                            },
                            esporte: {
                                elementtype: 'select',
                                elementconfig: {
                                    options: [
                                        { value: 1, displayValue: 'Futebol' },
                                        { value: 2, displayValue: 'Vôlei' },
                                        { value: 3, displayValue: 'Basquete' },
                                        { value: 4, displayValue: 'Tênis' },
                                        { value: 5, displayValue: 'Rugby' },
                                        { value: 6, displayValue: 'Outros' },
                                        { value: 9, displayValue: 'Futsal' },
                                        { value: 10, displayValue: 'Handebol' },
                                        { value: 11, displayValue: 'Judô' },
                                        { value: 12, displayValue: 'Punhobol' }
                                    ],
                                    label: 'Esporte'
                                },
                                value: response.data.sport,
                                valid: true
                            }
                        },
                    });
    
                }).catch(error => {
                   //console.log(error);
                });
            } else {
                this.setState({
                    ...this.state.form,
                    ads: resp.data,
                    form: {
                        titulo: {
                            elementtype: 'input',
                            elementconfig: {
                                type: 'text',
                                label: 'Título'
                            },
                            value: '',
                            validation: {
                                required: true,
                            },
                            valid: false,
                            touched: false
                        },
                        link: {
                            elementtype: 'input',
                            elementconfig: {
                                type: 'text',
                                label: 'Link'
                            },
                            value: '',
                            validation: {
                                required: false,
                            },
                            valid: true,
                            touched: false,
    
                        },
                        esporte: {
                            elementtype: 'select',
                            elementconfig: {
                                options: [
                                    { value: 1, displayValue: 'Futebol' },
                                    { value: 2, displayValue: 'Vôlei' },
                                    { value: 3, displayValue: 'Basquete' },
                                    { value: 4, displayValue: 'Tênis' },
                                    { value: 5, displayValue: 'Rugby' },
                                    { value: 6, displayValue: 'Outros' },
                                    { value: 9, displayValue: 'Futsal' },
                                    { value: 10, displayValue: 'Handebol' },
                                    { value: 11, displayValue: 'Judô' },
                                    { value: 12, displayValue: 'Punhobol' }
                                ],
                                label: 'Esporte'
                            },
                            value: 1,
                            valid: true
                        }
                    },
                });
            }
        }).catch();
        
    }

    onChangeThumb = (evt) => {
        const files = evt.target.files;
        this.setState({ thumbPreview: URL.createObjectURL(files[0]), thumb: files[0] });
    }

    onChangeEditor = (value) => {
        this.setState({ text: value });
    }

    onChangeAd = (value) => {
        this.setState({ selectedAds: value.map(a => a.id) });
    }

    submitFormHandler = () => {
        this.sendBasicData();
    }

    sendBasicData = () => {
        const formData = new FormData();

        formData.append("id", this.state.id);
        formData.append("title", this.state.form['titulo'].value);
        formData.append("link", this.state.form['link'].value);
        formData.append("sport", this.state.form['esporte'].value);
        formData.append("text", this.state.text);
        formData.append("thumb", this.state.thumb);
        
        let result = null;

        if(this.state.selectedAds){
            result = this.state.selectedAds;
        }

        if(result)
            formData.append("ads", result.join());
        
        const cookies = new Cookies();

        axios.post("/painel/noticia", formData, { headers: { 'J-Token': cookies.get("token") } }).then(resp => {
            this.props.history.push({ pathname: '/painel/noticias' });
        }).catch(err => {
            if (err.response.status === 401) {
                this.props.history.push({ pathname: '/painel/login' });
            }
        });
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedForm = {
            ...this.state.form
        }

        const updatedFormElement = { ...updatedForm[inputIdentifier] }

        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedForm[inputIdentifier] = updatedFormElement;
        let formIsValid = true;
        for (let inputIdentifiers in updatedForm) {
            formIsValid = updatedForm[inputIdentifiers].valid && formIsValid;
        }
        this.setState({ form: updatedForm, formIsValid: formIsValid });
       //console.log(updatedForm);
    }

    checkValidity(value, rules) {
        let isValid = true;

        if (!rules) {
            return isValid;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid;
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid;
        }

        return isValid;
    }

    render() {

        const formElementsArray = [];

        for (let key in this.state.form) {
            formElementsArray.push({
                id: key,
                config: this.state.form[key]
            });
        }

        let form = (
            <form>
                {formElementsArray.map(formElement => (
                    <Input key={formElement.id}
                        elementtype={formElement.config.elementtype}
                        elementconfig={formElement.config.elementconfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        mask={formElement.config.mask}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)} />
                ))}
            </form>
        );

        var thumbImage = {
            backgroundImage: 'url(' + this.state.thumbPreview + ')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: '180px',
            height: '180px'
        }

        return (
            <div className={classes.Edit}>
                <Row>
                    <Col m={9} s={12}>
                        <h1>Adicionar notícia</h1>
                        {form}
                    </Col>
                    <Col m={3} s={12}>
                        <h1>Thumb</h1>
                        <div style={thumbImage}>
                            <NewFileUploader onChange={this.onChangeThumb} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <h1>Ads laterais</h1>
                    {this.state.ads && <Multiselect
                        data={this.state.ads}
                        valueField='id'
                        textField='nome'
                        defaultValue={this.state.selectedAds}
                        onChange={this.onChangeAd}
                    />}
                </Row>
                <Row>
                    <TinyEditor data={this.state.text} onChange={this.onChangeEditor} />
                    <Button disabled={!this.state.formIsValid || (!this.state.thumb && this.state.id === 0) || !this.state.text} onclick={this.submitFormHandler}>Enviar</Button>
                </Row>
            </div>
        )
    }
}

export default Edit;