import React, { Component } from 'react';
import Spinner from '../../../components/UI/Spinner/Spinner';
import axios from '../../../axios';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import renderHTML from 'react-render-html';
import Share from '../../../components/UI/Share/Share';
import GalleryWidget from '../GalleryWidget/GalleryWidget';
import DetailWithAd from '../../../components/Notices/DetailWithAds/DetailWithAds';
import { Row, Col } from 'react-materialize';
//import ReactGA from 'react-ga';

import classes from './NoticesDetail.module.css';

class NoticesDetail extends Component {
    state = {
        notice: null,
        ads: null
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            axios.get('/noticia/' + this.props.match.params.id).then(response => {
                this.setState({
                    ...this.state,
                    notice: response.data
                });

                if(response.data.ads){
                    axios.get('/detal_props/' + response.data.ads).then(response => {
                        this.setState({
                            ...this.state,
                            ads: response.data
                        });
    
                    }).catch(error => {
                       //console.log(error);
                    });
                }

            }).catch(error => {
               //console.log(error);
            });
        }
    }

    onClickAd = (id) => {
        /*ReactGA.event({
            category: 'Banner',
            action: 'Click em um ad na notícia',
            label: 'id: ' + id
          });*/
    }

    render() {
        let not = <Spinner />
        if (this.state.notice) {
            let dateAdd = this.state.notice.user_add ? new Date(this.state.notice.data_add) : new Date();
            let dateEdit = this.state.notice.user_edit ? new Date(this.state.notice.data_edit) : new Date();
            not = (<div>
                <h1>{this.state.notice.title}</h1>
                {this.state.notice.user_add && <h2>Criado por <b>{this.state.notice.user_add}</b> - {dateAdd.toLocaleString()}</h2>}
                {this.state.notice.user_edit && <h2>Editado por <b>{this.state.notice.user_edit}</b> - {dateEdit.toLocaleString()}</h2>}
                <Share title={this.state.notice.title} url={window.location.href} />
                <Row>
                    <Col m={this.state.ads ? 10 : 12}>
                        {renderHTML(this.state.notice.body)}
                    </Col>
                    {this.state.ads &&
                        <Col m={2}>
                            <DetailWithAd ads={this.state.ads} onClick={this.onClickAd}/>
                        </Col>
                    }

                </Row>

                {this.state.notice.gallery && <GalleryWidget history={this.props.history} link={this.state.notice.gallery} />}
            </div>)
        }

        return (
            <div className={classes.NoticesDetail}>
                {not}
            </div>
        )
    }
}

export default withErrorHandler(NoticesDetail, axios);