import React, { Component } from 'react';

import Auxiliar from '../../hoc/Auxiliar/Auxiliar';
import { Switch, Route } from 'react-router';
import classes from './WithoutToolbarLayout.module.css';
import Login from '../../containers/Panel/Login/Login';

class WithoutToolbarLayout extends Component {
    render() {
        return (
            <Auxiliar>
                <main className={classes.container}>
                    <Switch>
                        <Route path="/painel/login" exact component={Login} />
                    </Switch>
                </main>
            </Auxiliar>
        )
    }
}

export default WithoutToolbarLayout;