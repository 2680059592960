import React, { Component } from 'react';
import { Row, Col } from 'react-materialize';
import Input from '../../../../components/UI/Input/Input';
import Button from '../../../../components/UI/Button/Button';
import axios from '../../../../axios';
import TinyEditor from '../../../../components/Panel/TinyEditor/TinyEditor';
import Cookies from 'universal-cookie';
import { Multiselect } from 'react-widgets'
import 'react-widgets/dist/css/react-widgets.css';

import classes from './Edit.module.css';

class Edit extends Component {
    state = {
        thumbPreview: null,
        text: '',
        id: 0,
        thumb: null,
        error: null,
        form: null,
        formIsValid: false,
        ads: null,
        selectedAds: null
    }

    componentDidMount() {
        const cookies = new Cookies();

        axios.get('/painel/props_news', { headers: { 'J-Token': cookies.get("token") } }).then(resp => {

            if (this.props.match.params.id > 0) {

                axios.get('/painel/materia/' + this.props.match.params.id, { headers: { 'J-Token': cookies.get("token") } }).then(response => {
                    this.setState({
                        ...this.state,
                        text: response.data.text,
                        id: this.props.match.params.id,
                        formIsValid: true,
                        ads: resp.data,
                        selectedAds: response.data.ads ? response.data.ads.split(',').map(String) : [],
                        form: {
                            titulo: {
                                elementtype: 'input',
                                elementconfig: {
                                    type: 'text',
                                    label: 'Título'
                                },
                                value: response.data.title,
                                validation: {
                                    required: true,
                                },
                                valid: true,
                                touched: true
                            },
                        },
                    });

                }).catch(error => {
                   //console.log(error);
                });
            } else {
                this.setState({
                    ...this.state.form,
                    ads: resp.data,
                    form: {
                        titulo: {
                            elementtype: 'input',
                            elementconfig: {
                                type: 'text',
                                label: 'Título'
                            },
                            value: '',
                            validation: {
                                required: true,
                            },
                            valid: false,
                            touched: false
                        },
                    },
                });
            }

        }).catch(err => { });
    }

    onChangeEditor = (value) => {
        this.setState({ text: value });
    }

    submitFormHandler = () => {
        this.sendBasicData();
    }

    onChangeAd = (value) => {
        this.setState({ selectedAds: value.map(a => a.id) });
    }

    sendBasicData = () => {
        this.setState({ error: null });
        const formData = new FormData();

        formData.append("id", this.state.id);
        formData.append("title", this.state.form['titulo'].value);
        formData.append("text", this.state.text);

        let result = null;

        if(this.state.selectedAds){
            result = this.state.selectedAds;
        }

        if(result)
            formData.append("ads", result.join());

        const cookies = new Cookies();

        axios.post("/painel/materia", formData, { headers: { 'J-Token': cookies.get("token") } }).then(resp => {
            this.props.history.push({ pathname: '/painel/materias' });
        }).catch(err => {
            if (err.response.status === 401) {
                this.props.history.push({ pathname: '/painel/login' });
            } else if (err.response.status === 403) {
                this.setState({ error: err.response.data.message })
            }
        });
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedForm = {
            ...this.state.form
        }

        const updatedFormElement = { ...updatedForm[inputIdentifier] }

        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedForm[inputIdentifier] = updatedFormElement;
        let formIsValid = true;
        for (let inputIdentifiers in updatedForm) {
            formIsValid = updatedForm[inputIdentifiers].valid && formIsValid;
        }
        this.setState({ form: updatedForm, formIsValid: formIsValid });
    }

    checkValidity(value, rules) {
        let isValid = true;

        if (!rules) {
            return isValid;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid;
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid;
        }

        return isValid;
    }

    render() {
        const formElementsArray = [];

        for (let key in this.state.form) {
            formElementsArray.push({
                id: key,
                config: this.state.form[key]
            });
        }

        let form = (
            <form>
                {formElementsArray.map(formElement => (
                    <Input key={formElement.id}
                        elementtype={formElement.config.elementtype}
                        elementconfig={formElement.config.elementconfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        mask={formElement.config.mask}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)} />
                ))}
            </form>
        );

        return (
            <div className={classes.Edit}>
                <h1>Adicionar matéria</h1>
                <Row>
                    <Col s={6}>
                        {form}
                    </Col>
                </Row>
                <Row>
                    <h1>Ads laterais</h1>
                    {this.state.ads && <Multiselect
                        data={this.state.ads}
                        valueField='id'
                        textField='nome'
                        defaultValue={this.state.selectedAds}
                        onChange={this.onChangeAd}
                    />}
                </Row>
                <Row>
                    <TinyEditor data={this.state.text} onChange={this.onChangeEditor} />
                    {this.state.error ? <h2>{this.state.error}</h2> : null}
                    <Button disabled={!this.state.formIsValid || !this.state.text /*|| !this.state.thumb*/} onclick={this.submitFormHandler}>Enviar</Button>
                </Row>
            </div>
        )
    }
}

export default Edit;